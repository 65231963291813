<template>
  <a-modal v-model:visible="visible" title="剧本列表" width="50%" v-highlight :footer="null">
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="题材" prop="filterTheme">
                <a-select
                  v-model="queryParam.filterTheme"
                  style="width: 100%"
                  placeholder="请选择题材"
                  :options="scriptFilterThemeOptions"
                  @change="selectFilterThemeHandleChange"
                ></a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['script:script:remove']">
          <a-icon type="file-add" />
          批量绑定
        </a-button>
        <!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['script:script:remove']">
                  <a-icon type="delete" />删除
                </a-button>
                <a-button type="primary" @click="handleExport" v-hasPermi="['script:script:export']">
                  <a-icon type="download" />导出
                </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <!--      <create-form
              ref="createForm"
              @ok="getList"
            />-->
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['script:script:edit']"/>
          <a-divider type="vertical" v-hasPermi="['script:script:remove']"/>
          <a @click="handleDelete(record)" v-hasPermi="['script:script:remove']">
            <a-icon type="file-add" />绑定
          </a>
        </span>
        <template slot="cover" slot-scope="text, record">
          <div>
            <img v-if="record.cover" :src="record.cover" style="width:60px;height:62px;" @preview="handlePreview"/>
          </div>
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-modal>
</template>

<script>
import {listScript, delScript} from '@/api/script/script'
import {tableMixin} from '@/store/table-mixin'
import {loadFilterOptions} from "@/api/script/scriptFilter";

export default {
  name: 'IndexScriptModal',
  components: {},
  mixins: [tableMixin],
  data() {
    return {
      visible: false,
      list: [],
      selectedRowKeys: [],
      scriptFilterThemeOptions: [],
      selectedRows: [],
      //预览封面
      previewVisible: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        shopId: null,
        cover: null,
        name: null,
        info: null,
        label: null,
        filterBackground: null,
        filterTheme: null,
        filterDifficulty: null,
        filterSellForm: null,
        filterType: null,
        personNum: null,
        humanNum: null,
        womanNum: null,
        talkType: null,
        talkInfo: null,
        author: null,
        seller: null,
        producer: null,
        storyBackground: null,
        officialEvaluation: null,
        hotValue: null,
        hotValueVirtual: null,
        likeValue: null,
        likeValueVirtual: null,
        salesValue: null,
        salesValueVirtual: null,
        popularValue: null,
        popularValueVirtual: null,
        themeValue: null,
        themeValueVirtual: null,
        role: null,
        isEnable: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '剧本名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '剧本封面',
          dataIndex: 'cover',
          scopedSlots: {customRender: 'cover'},
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '详情(多图)',
          dataIndex: 'info',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '剧本标签 标签库id,多个之间用逗号分隔',
          dataIndex: 'label',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '背景(外键)',
          dataIndex: 'filterBackground',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '题材 (外键)',
          dataIndex: 'filterTheme',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '难度 (外键)',
          dataIndex: 'filterDifficulty',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发售形式(外键)',
          dataIndex: 'filterSellForm',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '类型(外键)',
          dataIndex: 'filterType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '人数',
          dataIndex: 'personNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '男生人数',
          dataIndex: 'humanNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '女生人数',
          dataIndex: 'womanNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发行有话说类型',
          dataIndex: 'talkType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发行有话说',
          dataIndex: 'talkInfo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '作者',
          dataIndex: 'author',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发行',
          dataIndex: 'seller',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '监制',
          dataIndex: 'producer',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '故事背景',
          dataIndex: 'storyBackground',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '官方评测',
          dataIndex: 'officialEvaluation',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '热搜值',
          dataIndex: 'hotValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '虚拟热搜值',
          dataIndex: 'hotValueVirtual',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '点赞值',
          dataIndex: 'likeValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '虚拟点赞值',
          dataIndex: 'likeValueVirtual',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '票房值',
          dataIndex: 'salesValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '虚拟票房值',
          dataIndex: 'salesValueVirtual',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '人气值 xx日',
          dataIndex: 'popularValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '虚拟人气值 ',
          dataIndex: 'popularValueVirtual',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '题材值',
          dataIndex: 'themeValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '虚拟题材值',
          dataIndex: 'themeValueVirtual',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '角色',
          dataIndex: 'role',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '启用状态 1:启用 0:禁用',
          dataIndex: 'isEnable',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.initScriptFilterThemeHandleOptions()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询剧本列表 */
    getList() {
      this.loading = true
      listScript(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handlePreview(file) {
      console.log("打开了")
      this.previewAvatar = file.url || file.preview;
      this.previewVisible = true;
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    initScriptFilterThemeHandleOptions() {
      loadFilterOptions({type: 2}).then(res => {
        this.scriptFilterThemeOptions = res.data
      })
    },
    selectFilterThemeHandleChange(value) {
      this.form.filterTheme = value
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        cover: undefined,
        name: undefined,
        info: undefined,
        label: undefined,
        filterBackground: undefined,
        filterTheme: undefined,
        filterDifficulty: undefined,
        filterSellForm: undefined,
        filterType: undefined,
        personNum: undefined,
        humanNum: undefined,
        womanNum: undefined,
        talkType: undefined,
        talkInfo: undefined,
        author: undefined,
        seller: undefined,
        producer: undefined,
        storyBackground: undefined,
        officialEvaluation: undefined,
        hotValue: undefined,
        hotValueVirtual: undefined,
        likeValue: undefined,
        likeValueVirtual: undefined,
        salesValue: undefined,
        salesValueVirtual: undefined,
        popularValue: undefined,
        popularValueVirtual: undefined,
        themeValue: undefined,
        themeValueVirtual: undefined,
        role: undefined,
        isEnable: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      let ids = [];
      if (row.id) {
        ids.push(row.id)
      } else if (this.ids) {
        ids = this.ids
      }
      // const ids = row.id || this.ids;
      console.log('获取到的', ids);
      this.visible = false;
      this.$emit('selectActivity', ids)
      // this.$confirm({
      //   title: '确认删除所选中数据?',
      //   content: '当前选中编号为' + ids + '的数据',
      //   onOk () {
      //     return delScript(ids)
      //       .then(() => {
      //         that.onSelectChange([], [])
      //         that.getList()
      //         that.$message.success(
      //           '删除成功',
      //           3
      //         )
      //     })
      //   },
      //   onCancel () {}
      // })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('script/script/export', {
            ...that.queryParam
          }, `script_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    },
    open() {
      this.visible = true;
    }
  }
}
</script>
