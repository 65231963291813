<template>
  <div>
    <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
      <a-divider orientation="left">
        <b>{{ formTitle }}</b>
      </a-divider>
      <a-form-model ref="form" :model="form" :rules="rules">
        <!--      <a-form-model-item label="活动id" prop="id" v-if="formType === 1">
                <a-input v-model="form.id" placeholder="请输入活动id" />
              </a-form-model-item>-->
        <a-form-model-item label="活动名称" prop="activityName">
          <a-input v-model="form.activityName" placeholder="请输入活动名称"/>
        </a-form-model-item>
        <a-form-model-item label="封面" prop="activityPics">
<!--          <a-upload name="activityPics" listType="picture-card" class="avatar-uploader" :multiple="true"-->
<!--                    :show-upload-list="false" :preview="handlePreview" :before-upload="beforeOssUpload"-->
<!--                    :customRequest="imgAvatarUpload">-->
<!--            <img v-if="form.activityPics" :src="form.activityPics" alt="activityPics"-->
<!--                 style="height: 102px; width: 102px; border-radius: 50%"/>-->
<!--            <div v-else>-->
<!--              <a-icon :type="loading ? 'loading' : 'plus'"/>-->
<!--              <div class="ant-upload-text">上传</div>-->
<!--            </div>-->
<!--          </a-upload>-->
          <oss-multi-upload-single v-model="form.activityPics" type="inDoorImg" :maxSize="10"></oss-multi-upload-single>
        </a-form-model-item>
        <a-form-model-item label="活动月历图片展示" prop="show">
          <oss-multi-upload-single v-model="form.showi" type="inDoorImg" :maxSize="10"></oss-multi-upload-single>
        </a-form-model-item>
        <a-form-model-item label="活动报名开始时间" prop="applyStartTime">
          <a-date-picker style="width: 100%" @change="onDateChange" v-model="form.applyStartTime" :showTime="true"
                         format="YYYY-MM-DD HH:mm:ss"
                         allow-clear/>
        </a-form-model-item>
        <!--        <a-form-model-item label="活动报名结束时间" prop="applyEndTime">
          <a-date-picker style="width: 100%" @change="onDateChange1" :showTime="true" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
        </a-form-model-item>-->
        <a-form-model-item label="活动开始时间" prop="activityStartTime">
          <a-date-picker style="width: 100%" @change="onDateChange2" v-model="form.activityStartTime" :showTime="true"
                         format="YYYY-MM-DD HH:mm:ss"
                         allow-clear/>
        </a-form-model-item>
        <a-form-model-item label="活动结束时间" prop="activityEndTime">
          <a-date-picker style="width: 100%" @change="onDateChange3" v-model="form.activityEndTime" :showTime="true"
                         format="YYYY-MM-DD HH:mm:ss"
                         allow-clear/>
        </a-form-model-item>
        <!--        <a-form-model-item label="任务目标" prop="activityTaskGoal">-->
        <!--          <a-input v-model="form.activityTaskGoal" placeholder="请输入任务目标" />-->
        <!--        </a-form-model-item>-->
        <a-form-model-item label="任务目标类型" prop="activityTaskType">
          <a-select style="width: 100%" v-model="form.activityTaskType" placeholder="请输入任务目标类型" @change="changeType">
            <a-select-option v-for="(item, index) in this.customDict.ActivityTaskTypeEnum" :value="item.type"
                             :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="活动标签" prop="activityTags">
          <a-input disabled v-model="form.activityTags" placeholder="请输入活动标签，多个使用逗号隔开"/>

          <activity-form ref="ActivityForm" v-model="form.activityTags"></activity-form>
          <!--          <div class="roleLabelClass" v-for="(item, index) in form.activityTags" :key="index" style="float: left">-->
          <!--            <a-button type="dashed" @click="roleClick(index)" danger>{{ item.activityTags }}</a-button>-->
          <!--          </div>-->
          <!-- 角色添加按钮 -->
          <!--          <div v-show="!form.activityTags">-->
          <a-button type="dashed" @click="$refs.ActivityForm.roleClick(form.activityTags)" danger><span
            style="color: dodgerblue">新增活动标签</span></a-button>
          <!--          </div>-->


        </a-form-model-item>

        <a-form-model-item label="剧本" prop="scriptIds" v-if="isShow">
          <api-select v-model="form.scriptIds" placeholder="请选择剧本" api="/script/script/select-list" multi disabled=""
                      :searchKeys="['name']">
            <template v-slot="{ option }">
              <span>{{ option.name }}</span>
            </template>
          </api-select>
          <p>
            <a @click="handleInfo(form.id)">绑定剧本</a>
          </p>
        </a-form-model-item>
        <a-form-model-item label="任务目标完成要求次数" prop="activityTaskNum" v-if="isShow2">
          <a-input-number v-model:value="form.activityTaskNum" :min="0" :max="9999" :precision="0"
                          placeholder="请输入任务目标完成要求次数"/>
        </a-form-model-item>
        <a-form-model-item label="任务二中需要的点赞数量" prop="likeNum">
          <!--          <a-input v-model="form.likeNum" placeholder="请输入任务二中需要的点赞数量" />-->
          <a-input-number v-model:value="form.likeNum" :min="0" :max="9999" :precision="0"
                          placeholder="请输入任务二中需要的点赞数量"/>
        </a-form-model-item>
        <a-form-model-item label="任务二中需要的评论超过字数" prop="wordNum">
          <!--          <a-input v-model="form.wordNum" placeholder="请输入任务二中需要的评论超过字数" />-->
          <a-input-number v-model:value="form.wordNum" :min="0" :max="9999" :precision="0"
                          placeholder="请输入任务二中需要的评论超过字数"/>
        </a-form-model-item>
        <!-- 关联套餐奖励 -->
        <a-form-model-item label="关联套餐奖励" prop="rebindIds" >
          <api-select v-model="form.rebindIds" placeholder="请选择套餐奖励" api="/activity/activity/reward/select-list" multi
                      :searchKeys="['name']">
            <template v-slot="{ option }">
              <span>{{ option.name }}</span>
            </template>
          </api-select>
        </a-form-model-item>
        <!-- 关联周边奖励 -->
        <a-form-model-item label="关联周边奖励" prop="sibindIds">
          <api-select v-model="form.sibindIds" placeholder="请选择题材" api="/activity/activity/side/select-list" multi
                      :searchKeys="['name']">
            <template v-slot="{ option }">
              <span>{{ option.name }}</span>
            </template>
          </api-select>
        </a-form-model-item>
        <!-- 关联题材 -->
        <a-form-model-item label="题材" prop="themeIds" v-if="isShow1">
          <api-select v-model="form.themeIds" placeholder="请选择题材" api="/script/script/filter/loadFilterOptionsTheme"
                      multi
                      :searchKeys="['name']">
            <template v-slot="{ option }">
              <span>{{ option.name }}</span>
            </template>
          </api-select>
        </a-form-model-item>
        <!--        <a-form-model-item label="剧本分类类型" prop="themeId">-->
        <!--          <a-select v-model="form.themeId" style="width: 100%" placeholder="请选择剧本分类类型" :options="scriptFilterThemeOptions"-->
        <!--            @change="selectFilterThemeHandleChange"></a-select>-->
        <!--        </a-form-model-item>-->

        <!--        <a-form-model-item label="活动状态" prop="activityStatus">-->
        <!--          <a-select style="width: 100%" v-model="form.activityStatus" placeholder="请输入标签">-->
        <!--            <a-select-option v-for="(item, index) in this.customDict.ActivityStatusEnum" :value="item.type" :key="index">-->
        <!--              {{ item.name }}-->
        <!--            </a-select-option>-->
        <!--          </a-select>-->
        <!--        </a-form-model-item>-->
        <a-form-model-item label="排序，数字越小，排名越靠前" prop="sortNum">
          <!--          <a-input v-model="form.sortNum" placeholder="请输入排序，数字越小，排名越靠前" />-->
          <a-input-number v-model:value="form.sortNum" :min="0" :max="9999" :precision="0"
                          placeholder="请输入任务二中需要的评论超过字数"/>
        </a-form-model-item>
        <!--      <a-form-model-item label="活动报名人数" prop="activityApplyNum" >
                <a-input v-model="form.activityApplyNum" placeholder="请输入活动报名人数" />
              </a-form-model-item>-->
        <!--      <a-form-model-item label="活动参与人数" prop="applyJoinNum" >
                <a-input v-model="form.applyJoinNum" placeholder="请输入活动参与人数" />
              </a-form-model-item>
              <a-form-model-item label="活动完成人数" prop="activityCompleteNum" >
                <a-input v-model="form.activityCompleteNum" placeholder="请输入活动完成人数" />
              </a-form-model-item>
              <a-form-model-item label="创建人" prop="createBy" >
                <a-input v-model="form.createBy" placeholder="请输入创建人" />
              </a-form-model-item>
              <a-form-model-item label="修改人" prop="updateBy" >
                <a-input v-model="form.updateBy" placeholder="请输入修改人" />
              </a-form-model-item>
              -->
        <!--      <a-form-model-item label="活动年份，活动日历使用" prop="activityYear" >
                <a-input v-model="form.activityYear" placeholder="请输入活动年份，活动日历使用" />
              </a-form-model-item>
              <a-form-model-item label="活动月份，活动日历使用" prop="activityMonth" >
                <a-input v-model="form.activityMonth" placeholder="请输入活动月份，活动日历使用" />
              </a-form-model-item>-->

        <!--      <a-form-model-item label="运费模板id" prop="frieghtId" >
                <a-input v-model="form.frieghtId" placeholder="请输入运费模板id" />
              </a-form-model-item>-->
        <!--      <a-form-model-item label="申请邮寄截止时间" prop="applyPostEndTime" >
                <a-date-picker style="width: 100%" v-model="form.applyPostEndTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-model-item>-->
        <!--        <a-form-model-item label="活动价格" prop="acPrice">-->
        <!--          <a-input v-model="form.acPrice" placeholder="请输入活动价格" />-->
        <!--        </a-form-model-item>-->
        <!--        <a-form-model-item label="奖励类型" prop="prizeType">-->
        <!--          <a-select style="width: 100%" v-model="form.prizeType" placeholder="请输入标签">-->
        <!--            <a-select-option v-for="(item, index) in this.customDict.PrizeTypeEnum" :value="item.type" :key="index">-->
        <!--              {{ item.name }}-->
        <!--            </a-select-option>-->
        <!--          </a-select>-->
        <!--        </a-form-model-item>-->
        <!--        <a-form-model-item label="活动奖励" prop="prize">-->
        <!--          <a-upload name="prize" v-model="fileList" listType="picture-card" :fileList="fileList" :remove="removeFile"-->
        <!--            class="avatar-uploader" :multiple="true" :show-upload-list="true" :preview="handlePreview"-->
        <!--            :before-upload="beforeOssUpload" :customRequest="coverAvatarUploadInfo">-->
        <!--            <div>-->
        <!--              <a-icon :type="loading ? 'loading' : 'plus'" />-->
        <!--              <div class="ant-upload-text">上传</div>-->
        <!--            </div>-->
        <!--          </a-upload>-->
        <!--        </a-form-model-item>-->

        <a-form-model-item label="活动详情多图" prop="activityDetailPics">
          <a-upload name="activityDetailPics" v-model="fileList1" listType="picture-card" :fileList="fileList1"
                    :remove="removeFile1" class="avatar-uploader" :multiple="true" :show-upload-list="true"
                    :preview="handlePreview" :before-upload="beforeOssUpload" :customRequest="coverAvatarUploadInfo1">
            <div>
              <a-icon :type="loading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <!--        <a-form-model-item label="奖励详情多图" prop="prizeDetail">-->
        <!--          <a-upload name="prizeDetail" v-model="fileList2" listType="picture-card" :fileList="fileList2"-->
        <!--            :remove="removeFile2" class="avatar-uploader" :multiple="true" :show-upload-list="true"-->
        <!--            :preview="handlePreview" :before-upload="beforeOssUpload" :customRequest="coverAvatarUploadInfo2">-->
        <!--            <div>-->
        <!--              <a-icon :type="loading ? 'loading' : 'plus'" />-->
        <!--              <div class="ant-upload-text">上传</div>-->
        <!--            </div>-->
        <!--          </a-upload>-->
        <!--        </a-form-model-item>-->

        <a-form-model-item label="背景色" prop="backColor">
          <a-input v-model="form.backColor" placeholder="请输入背景色"/>
        </a-form-model-item>
        <a-form-model-item label="底部按钮背景色" prop="mainColor">
          <a-input v-model="form.mainColor" placeholder="请输入字体主色"/>
        </a-form-model-item>
        <!--        <a-form-model-item label="字体副色" prop="assistantColor">-->
        <!--          <a-input v-model="form.assistantColor" placeholder="请输入字体副色" />-->
        <!--        </a-form-model-item>-->

        <a-form-model-item label="报名人数上限" prop="applyMaxNum">
          <!--          <a-input v-model="form.applyMaxNum" placeholder="请输入报名人数上限" />-->
          <a-input-number v-model:value="form.applyMaxNum" :min="0" :max="9999" :precision="0" placeholder="请输入报名人数上限"/>
        </a-form-model-item>
        <a-form-model-item label="虚拟报名人数" prop="activityVirtualApplyNum">
          <!--          <a-input v-model="form.activityVirtualApplyNum" placeholder="请输入虚拟报名人数" />-->
          <a-input-number v-model:value="form.activityVirtualApplyNum" :min="0" :max="9999" :precision="0"
                          placeholder="请输入虚拟报名人数"/>
        </a-form-model-item>

        <!--        <a-form-model-item label="活动描述，活动详情" prop="activityDesc">-->
        <!--          <a-input v-model="form.activityDesc" placeholder="请输入内容" type="textarea" allow-clear />-->
        <!--        </a-form-model-item>-->
        <a-form-model-item label="手动输入，数字，需要小于详情图数量。文本在第几张图片展示" prop="textShowIndex">
          <!--          <a-input v-model="form.textShowIndex" placeholder="请输入必传，手动输入，数字，需要小于详情图数量。文本在第第几张图片展示" />-->
          <a-input-number v-model:value="form.textShowIndex" :min="0" :max="9999" :precision="0"
                          placeholder="请输入必传，手动输入，数字，需要小于详情图数量。文本在第第几张图片展示"/>
        </a-form-model-item>
        <!-- 关联徽章 -->
        <a-form-model-item label="关联徽章" prop="badgeIds" v-if="isShow2">
          <api-select v-model="form.badgeIds" placeholder="请选择徽章" api="/config/badge/select-list"
                      :searchKeys="['reach', 'typeName', 'level']">
            <template v-slot="{ option }">
              <div class="badge-select-img">
                <a-avatar class="badge-select-img-not-light" :size="32" :src="option.notLightImg" icon="file-image"/>
                <a-avatar class="badge-select-img-light" :size="32" :src="option.lightImg" icon="file-image"/>
              </div>
              <a-tag style="margin-left: 8px">Lv.{{ option.level }}</a-tag>
              <a-tag>{{ option.typeName }}</a-tag>
              <span :title="option.reach">{{ option.name }}</span>
            </template>
          </api-select>
        </a-form-model-item>
        <!-- 关联称号 -->
        <a-form-model-item label="关联称号" prop="appellationIds" v-if="isShow2">
          <api-select v-model="form.appellationIds" placeholder="请选择称号" api="/config/appellation/select-list"
                      :searchKeys="['reach', 'typeName', 'level']">
            <template v-slot="{ option }">
              <div class="badge-select-img">
                <a-avatar shape="square" class="badge-select-img-not-light" :size="32" :src="option.notLightImg"
                          icon="file-image"/>
                <a-avatar shape="square" class="badge-select-img-light" :size="32" :src="option.img" icon="file-image"/>
              </div>
              <a-tag style="margin-left: 8px">Lv.{{ option.level }}</a-tag>
              <a-tag>
                <custom-dict-tag :options="customDict.TitleTypeEnum" :value="option.type"/>
              </a-tag>
              <span :title="option.reach">{{ option.name }}</span>
            </template>
          </api-select>
        </a-form-model-item>

        <!-- 关联徽章 -->
        <a-form-model-item label="关联徽章" prop="badgeIds" v-if="isShow3">
          <api-select v-model="form.badgeIds" placeholder="请选择徽章" api="/config/badge/select-list1"
                      :searchKeys="['reach', 'typeName', 'level']">
            <template v-slot="{ option }">
              <div class="badge-select-img">
                <a-avatar class="badge-select-img-not-light" :size="32" :src="option.notLightImg" icon="file-image"/>
                <a-avatar class="badge-select-img-light" :size="32" :src="option.lightImg" icon="file-image"/>
              </div>
              <a-tag style="margin-left: 8px">Lv.{{ option.level }}</a-tag>
              <a-tag>{{ option.typeName }}</a-tag>
              <span :title="option.reach">{{ option.name }}</span>
            </template>
          </api-select>
        </a-form-model-item>
        <!-- 关联称号 -->
        <a-form-model-item label="关联称号" prop="appellationIds" v-if="isShow3">
          <api-select v-model="form.appellationIds" placeholder="请选择称号" api="/config/appellation/select-list1"
                      :searchKeys="['reach', 'typeName', 'level']">
            <template v-slot="{ option }">
              <div class="badge-select-img">
                <a-avatar shape="square" class="badge-select-img-not-light" :size="32" :src="option.notLightImg"
                          icon="file-image"/>
                <a-avatar shape="square" class="badge-select-img-light" :size="32" :src="option.img" icon="file-image"/>
              </div>
              <a-tag style="margin-left: 8px">Lv.{{ option.level }}</a-tag>
              <a-tag>
                <custom-dict-tag :options="customDict.TitleTypeEnum" :value="option.type"/>
              </a-tag>
              <span :title="option.reach">{{ option.name }}</span>
            </template>
          </api-select>
        </a-form-model-item>

        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              保存
            </a-button>
            <a-button type="dashed" @click="cancel">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </a-drawer>
    <index-script-modal ref="IndexScriptModal" @selectActivity="indexScriptModalSelect"></index-script-modal>
  </div>
</template>

<script>
import {getActivity, addActivity, updateActivity} from '@/api/activity/activity'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from '@/api/tool/alioss'
import IndexScriptModal from "@/views/activity/activity/modules/IndexScriptModal";
import {loadFilterOptions} from "@/api/script/scriptFilter";
import ApiSelect from '@/views/config/badge/modules/ApiSelect.vue';
import ActivityForm from "./ActivityForm.vue";
import MultiRoleForm from "@/views/script/script/modules/MultiRoleForm.vue";
import OssMultiUploadSingle from "@/views/script/script/modules/OssMultiUploadSingle";

export default {
  name: 'CreateForm',
  props: {},
  components: {OssMultiUploadSingle, MultiRoleForm, ActivityForm, IndexScriptModal, ApiSelect, CustomDictTag},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      isShow: true,
      isShow1: true,
      isShow2: true,
      isShow3: true,
      fileList: [],
      fileList1: [],
      fileList2: [],
      filterThemes: [],
      scriptFilterThemeOptions: [],
      loading: false,
      // 表单参数
      form: {
        id: null,

        activityName: null,

        applyStartTime: null,

        applyEndTime: null,

        activityStartTime: null,

        activityEndTime: null,

        activityTags: null,

        scriptId: null,

        themeId: null,

        activityStatus: null,

        activityDesc: null,

        activityApplyNum: null,

        applyJoinNum: null,

        activityCompleteNum: null,

        createBy: null,

        updateBy: null,

        createTime: null,

        updateTime: null,

        sortNum: null,

        isTop: null,

        activityPics: null,
        showi: null,

        activityYear: null,

        activityMonth: null,

        activityTaskGoal: null,

        activityTaskType: null,

        frieghtId: null,

        applyPostEndTime: null,

        acPrice: null,

        prizeType: null,
        activityDetailPics: null,
        prizeDetail: null,
        backColor: null,
        mainColor: null,
        assistantColor: null,
        prize: null,

        activityTaskNum: null,
        likeNum: null,
        wordNum: null,
        applyMaxNum: null,

        activityVirtualApplyNum: null,

        textShowIndex: null,
        badgeIds: [],
        scriptIds: [],
        themeIds: [],
        rebindIds: [],
        sibindIds: [],
        appellationIds: []

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        activityName: [
          {required: true, message: '名称不能为空', trigger: 'blur'}
        ],
        activityPics: [
          {required: true, message: '活动封面不能为空', trigger: 'blur'}
        ],
        showi: [
          {required: true, message: '活动月历展示图片不能为空', trigger: 'blur'}
        ],
        applyStartTime: [
          {required: true, message: '活动报名开始时间不能为空', trigger: 'blur'}
        ],
        activityStartTime: [
          {required: true, message: '活动开始时间不能为空', trigger: 'blur'}
        ],
        activityEndTime: [
          {required: true, message: '活动结束时间不能为空', trigger: 'blur'}
        ],
        activityTaskType: [
          {required: true, message: '任务目标类型不能为空', trigger: 'blur'}
        ],
        likeNum: [
          {required: true, message: '任务二中的点赞数量不能为空', trigger: 'blur'}
        ],
        // activityTaskGoal: [
        //   { required: true, message: '任务目标不能为空', trigger: 'blur' }
        // ],
        wordNum: [
          {required: true, message: '任务二中需要的评论超过字数不能为空', trigger: 'blur'}
        ],
        rebindIds: [
          {required: true, message: '关联套餐奖励不能为空', trigger: 'blur'}
        ],
        activityTaskNum: [
          {required: true, message: '任务目标完成要求次数不能为空', trigger: 'blur'}
        ],
        backColor: [
          {required: true, message: '背景色不能为空', trigger: 'blur'},
          {
            pattern: /^#[0-9a-fA-F]{6}$/,
            message: '请输入正确的色值:例如：#463E38'
          }
        ],
        mainColor: [
          {required: true, message: '底部按钮背景色不能为空', trigger: 'blur'},
          {
            pattern: /^#[0-9a-fA-F]{6}$/,
            message: '请输入正确的色值:例如：#463E38'
          }
        ],
        applyMaxNum: [
          {required: true, message: '报名人数上限不能为空', trigger: 'blur'}
        ],
        // assistantColor: [
        //   { required: true, message: '字体副色不能为空', trigger: 'blur' },
        //   {
        //     pattern: /^#[0-9a-fA-F]{6}$/,
        //     message:'请输入正确的色值:例如：#463E38'
        //   }
        // ],
      }
    }
  },
  filters: {},
  created() {
    this.initScriptFilterThemeHandleOptions()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    indexScriptModalSelect(ids) {
      //this.form.scriptIds = ids;
      ids.forEach((item, index) => {
        this.form.scriptIds.push(item)
        console.log("获取到的ids", ids);
        console.log("获取到的scriptIds", this.form.scriptIds);
      })
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.fileList = []
      this.isShow = true
      this.isShow1 = true
      this.isShow2 = true
      this.isShow3 = true
      this.$nextTick(() => {
        let formref = this.$refs.form
        if (formref) {
          formref.resetFields()
        }

      })
      this.fileList1 = []
      this.fileList2 = []
      this.form = {
        id: null,

        activityName: null,

        applyStartTime: null,

        applyEndTime: null,

        activityStartTime: null,

        activityEndTime: null,

        activityTags: null,

        scriptId: null,

        themeId: null,

        activityStatus: null,

        activityDesc: null,

        activityApplyNum: null,

        applyJoinNum: null,

        activityCompleteNum: null,

        createBy: null,

        updateBy: null,

        createTime: null,

        updateTime: null,

        sortNum: null,

        isTop: null,

        activityPics: null,
        showi: null,
        activityYear: null,

        activityMonth: null,

        activityTaskGoal: null,

        activityTaskType: null,

        frieghtId: null,

        applyPostEndTime: null,

        acPrice: null,

        prizeType: null,
        activityDetailPics: null,
        prizeDetail: null,
        backColor: null,
        mainColor: null,
        assistantColor: null,
        prize: null,

        activityTaskNum: null,
        likeNum: null,
        wordNum: null,
        applyMaxNum: null,

        activityVirtualApplyNum: null,

        textShowIndex: null,
        badgeIds: [],
        scriptIds: [],
        themeIds: [],
        rebindIds: [],
        sibindIds: [],
        appellationIds: []
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.isShow = null
      this.isShow1 = null
      this.isShow2 = null
      this.isShow3 = null
      this.open = true
      this.formTitle = '添加'
    },
    handleInfo: function (id) {
      this.$refs.IndexScriptModal.open();
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },
    initScriptFilterThemeHandleOptions() {
      loadFilterOptions({type: 2}).then(res => {
        this.scriptFilterThemeOptions = res.data
      })
    },
    selectFilterThemeHandleChange(value) {
      this.form.filterDifficulty = value
    },
    /*selectFilterThemeHandleChange(value) {
      if (value.length > 2) {
        this.$message.error('题材最多选择两个')
        var filterThemes = []
        filterThemes.push(value[0])
        filterThemes.push(value[1])
        this.filterThemes = filterThemes
        return
      }
      this.filterThemes = value
      this.form.filterTheme = ''
      this.filterThemes.forEach(e => {
        this.form.filterTheme += (e + ',')
      })
      this.form.filterTheme = this.form.filterTheme.substring(0, this.form.filterTheme.length - 1)
    },*/
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getActivity(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        // 处理图片反显
        if (response.data.prize !== undefined && response.data.prize !== null && JSON.stringify(response.data.prize) !== '"{}"') {
          var images = this.form.prize.split(',')
          for (var i = 0; i < images.length; i++) {
            this.fileList.push({status: 'done', url: images[i], uid: this.getUidRandom(), name: 'prize.jpg'})
          }
        }
        if (response.data.activityDetailPics !== undefined && response.data.activityDetailPics !== null && JSON.stringify(response.data.activityDetailPics) !== '"{}"') {
          var images = this.form.activityDetailPics.split(',')
          for (var i = 0; i < images.length; i++) {
            this.fileList1.push({
              status: 'done',
              url: images[i],
              uid: this.getUidRandom(),
              name: 'activityDetailPics.jpg'
            })
          }
        }
        // 处理题材反显
        // this.filterThemes = this.form.filterTheme.split(',')

        if (response.data.prizeDetail !== undefined && response.data.prizeDetail !== null && JSON.stringify(response.data.prizeDetail) !== '"{}"') {
          var images = this.form.prizeDetail.split(',')
          for (var i = 0; i < images.length; i++) {
            this.fileList2.push({status: 'done', url: images[i], uid: this.getUidRandom(), name: 'prizeDetail.jpg'})
          }
        }
      })
    },
    changeType(value) {
      console.log("123" + value);
      this.isShow = false;
      if (value == 1) {
        this.isShow = true;
        this.isShow1 = false;
        this.isShow2 = false;
        this.isShow3 = true;
      }
      if (value == 2) {
        this.isShow = true;
        this.isShow1 = false;
        this.isShow2 = true;
        this.isShow3 = false;
      }
      if (value == 3) {
        this.isShow = false;
        this.isShow1 = false;
        this.isShow2 = true;
        this.isShow3 = false;
      }
      if (value == 4) {
        this.isShow = false;
        this.isShow1 = true;
        this.isShow2 = true;
        this.isShow3 = false;
      }
    },
    onDateChange(date, dateString) {
      debugger
      console.log(date, dateString)
      this.form.applyStartTime = dateString
    },
    onDateChange1(date, dateString) {
      console.log(date, dateString)
      this.form.applyEndTime = dateString
    },
    onDateChange2(date, dateString) {
      console.log(date, dateString)
      this.form.activityStartTime = dateString
    },
    onDateChange3(date, dateString) {
      console.log(date, dateString)
      this.form.activityEndTime = dateString
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    imgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'activityPics'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'activityPics', res)
          _this.$message.success('上传成功')
        })
      })
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束
    // 删除图片
    removeFile(file) {
      var fileList = []
      this.fileList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      this.fileList = fileList
      var info = ''
      fileList.forEach(e => {
        if (e.url !== undefined && e.url !== null && e.url !== '') {
          info += (e.url + ',')
        }
      })
      info = info.substring(0, info.length - 1)
      this.$set(this.form, 'prize', info)
      console.log('info=', _this.form.prize)
    },
    removeFile1(file) {
      var fileList1 = []
      this.fileList1.forEach(e => {
        if (e.url !== file.url) {
          fileList1.push(e)
        }
      })
      this.fileList1 = fileList1
      var info = ''
      fileList1.forEach(e => {
        if (e.url !== undefined && e.url !== null && e.url !== '') {
          info += (e.url + ',')
        }
      })
      info = info.substring(0, info.length - 1)
      this.$set(this.form, 'activityDetailPics', info)
      console.log('info=', _this.form.activityDetailPics)
    },
    removeFile2(file) {
      var fileList2 = []
      this.fileList2.forEach(e => {
        if (e.url !== file.url) {
          fileList2.push(e)
        }
      })
      this.fileList2 = fileList2
      var info = ''
      fileList2.forEach(e => {
        if (e.url !== undefined && e.url !== null && e.url !== '') {
          info += (e.url + ',')
        }
      })
      info = info.substring(0, info.length - 1)
      this.$set(this.form, 'prizeDetail', info)
      console.log('info=', _this.form.prizeDetail)
    },
    // 上传详情覆盖默认的上传行为
    coverAvatarUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'prize'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)

          _this.fileList.push({status: 'done', url: res, uid: this.getUidRandom(), name: 'image.jpg'})
          var info = ''
          _this.fileList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'prize', info)
          console.log('info=', _this.form.info)
          _this.$message.success('上传成功')
        })
      })
    },
    // 上传详情覆盖默认的上传行为
    coverAvatarUploadInfo1(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'prize'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)

          _this.fileList1.push({status: 'done', url: res, uid: this.getUidRandom(), name: 'image.jpg'})
          var info = ''
          _this.fileList1.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'activityDetailPics', info)
          console.log('info=', _this.form.info)
          _this.$message.success('上传成功')
        })
      })
    },
    // 上传详情覆盖默认的上传行为
    coverAvatarUploadInfo2(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'prize'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)

          _this.fileList2.push({status: 'done', url: res, uid: this.getUidRandom(), name: 'image.jpg'})
          var info = ''
          _this.fileList2.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'prizeDetail', info)
          console.log('info=', _this.form.info)
          _this.$message.success('上传成功')
        })
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (typeof this.form.badgeIds === "string") {
            let badgeIds = this.form.badgeIds.split(",")
            this.form.badgeIds = badgeIds
          }
          if (typeof this.form.appellationIds == "string") {
            let appellationIds = this.form.appellationIds.split(",")
            this.form.appellationIds = appellationIds
          }
          if (this.form.activityTags) {
            if (this.form.activityTags instanceof Array) {
              this.form.activityTags = this.form.activityTags.join(",");
            }
          }
          if (this.form.id !== undefined && this.form.id !== null) {
            updateActivity(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addActivity(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    // roleClick(value) {
    //   this.$refs.ActivityForm.open(value);
    // },
  }
}
</script>
<style scoped>
.badge-select-img {
  position: relative;
  display: inline-block;
}

.badge-select-img-light {
  position: relative;
}

.badge-select-img-not-light {
  position: absolute;
}

.badge-select-img:hover .badge-select-img-not-light {
  z-index: 10;
}
</style>