<!-- 多角色添加/编辑弹窗 -->
<template>
  <a-modal v-model="visible" title="编辑角色" :width="800" @ok="handleOk">
    <div>
      <div v-for="(item, index) in thisValue">
        <a-button type="danger" @click="remove(index)">移除 {{ thisValue[index].roleName }}</a-button>
        <a-form-model-item :label="'角色' + (index + 1) + ' 名称'" :rules="rules">
          <a-input v-model="thisValue[index].roleName" placeholder="请输入内容" allow-clear />
        </a-form-model-item>
        <a-form-model-item :label="'角色' + (index + 1) + ' 图片 (只上传一张)'">
          <oss-multi-upload v-model="thisValue[index].roleImg" type="img"></oss-multi-upload>
        </a-form-model-item>
      </div>
      <a-button @click="add" type="primary">添加</a-button>
    </div>
  </a-modal>
</template>

<script>
import OssMultiUpload from './OssMultiUploadSingle.vue'
export default {
  name: 'MultiRoleForm',
  components: { OssMultiUpload },
  data() {
    return {
      visible: false, // 弹窗隐藏/显示
      thisValue: [],
      imgUpload: [], // 图片上传需要的数据

      rules: {
        roleName: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(roles) {
      this.visible = true
      if (!roles || !roles.length) {
        this.thisValue = [{
          roleName: '',
          roleImg: ''
        }]
        return
      }
      this.thisValue = roles.map(role => {
        return {
          roleName: role.roleName,
          roleImg: role.roleImg
        }
      })
    },
    add() {
      this.thisValue.push({
        roleName: '',
        roleImg: ''
      })
    },
    remove(index) {
      this.thisValue.splice(index, 1)
    },
    handleOk() {
      this.$emit('input', this.thisValue)
      this.visible = false
    }
  }
}
</script>
