import request from '@/utils/request'

// 查询剧本筛选项列表
export function listScriptFilter(query) {
  return request({
    url: '/script/script/filter/list',
    method: 'get',
    params: query
  })
}

export function loadFilterOptions(query) {
  return request({
    url: '/script/script/filter/loadFilterOptions',
    method: 'get',
    params: query
  })
}

// 查询剧本筛选项详细
export function getScriptFilter(id) {
  return request({
    url: '/script/script/filter/' + id,
    method: 'get'
  })
}

// 新增剧本筛选项
export function addScriptFilter(data) {
  return request({
    url: '/script/script/filter',
    method: 'post',
    data: data
  })
}

// 修改剧本筛选项
export function updateScriptFilter(data) {
  return request({
    url: '/script/script/filter',
    method: 'put',
    data: data
  })
}

// 删除剧本筛选项
export function delScriptFilter(id) {
  return request({
    url: '/script/script/filter/' + id,
    method: 'delete'
  })
}
