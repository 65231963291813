<!-- 多角色添加/编辑弹窗 -->
<template>
  <a-modal v-model="visible" title="活动标签" :width="800" @ok="handleOk">
    <div>
      <div v-for="(item, index) in thisValue">
        <a-button type="danger" @click="remove(index)">移除标签: {{ thisValue[index] }}</a-button>
        <a-form-model-item :label="'标签：' + (index + 1)">
          <a-input v-model="thisValue[index]" placeholder="请输入内容" allow-clear />
        </a-form-model-item>
      </div>
      <a-button @click="add" type="primary">添加</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'ActivityForm',
  components: {  },
  data() {
    return {
      visible: false, // 弹窗隐藏/显示
      thisValue: [],
    }
  },
  methods: {
    roleClick(roles) {
      this.visible = true;
      if (roles) {
        this.thisValue = roles.split(",");
      }
    },
    add() {
      this.thisValue.push("");
    },
    remove(index) {
      this.thisValue.splice(index, 1);
    },
    handleOk() {
      this.$emit('input', this.thisValue);
      this.visible = false;
    }
  },
}
</script>